import '../App.css';
import React, {useState} from "react";
import logo from "../images/HyperZone_horiz_for-orange-bg.png";
import { GiHamburgerMenu } from "react-icons/gi";
import {MdClose} from "react-icons/md";

function Header() {
    const [open, setOpen] = useState("closed")

    function mobileMenuOpen() {
        if (open === "closed") {
            setOpen("opened")
        } else {
            setOpen("closed")
        }
    }
    return (
        <header>
            <div className={"header-container"}>
                <div className={"header-column1"}>
                    <div className={"img-wrapper"}>
                        <a href="https://hyperzone.omnihyper.com/">
                            <img src={logo} alt={"Hyperzone Logo"} width={"210"}/>
                        </a>
                    </div>
                </div>
                <div className={"header-column2"}>
                    <div className={"menu-header"}>
                        <div className={"menu-container"}>
                            <div className={"menu-wrapper"}>
                                <div className={"menu"}>
                                    <nav className="menu-nav">
                                        <ul id="menu-main-menu" className="et-menu nav">
                                            <li className="et_pb_menu_page_id-106 menu-item menu-item-type-post_type menu-item-object-page menu-item-111">
                                                <a href="https://hyperzone.omnihyper.com/research-placement/">RESEARCH &amp; PLACEMENT</a>
                                            </li>
                                            <li className="et_pb_menu_page_id-261 menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-261 current_page_item menu-item-265">
                                                <a href="https://hyperzone.omnihyper.com/solutions/"
                                                   aria-current="page">SOLUTIONS</a>
                                            </li>
                                            <li className="et_pb_menu_page_id-460 menu-item menu-item-type-post_type menu-item-object-page menu-item-465">
                                                <a href="https://hyperzone.omnihyper.com/pricing/">PRICING</a></li>
                                            <li className="et_pb_menu_page_id-242522 menu-item menu-item-type-post_type menu-item-object-page menu-item-242530">
                                                <a href="https://hyperzone.omnihyper.com/results/">RESULTS</a></li>
                                            <li className="et_pb_menu_page_id-652 menu-item menu-item-type-post_type menu-item-object-page menu-item-678">
                                                <a href="https://hyperzone.omnihyper.com/faq/">FAQ</a></li>
                                            <li className="et_pb_menu_page_id-243085 menu-item menu-item-type-post_type menu-item-object-page menu-item-243987">
                                                <a href="https://hyperzone.omnihyper.com/news-blog/">NEWS &amp; BLOG</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div className="menu-mobile">
                                    <div className={"nav-mobile " + open}>
                                        <span className="mobile_menu_bar" onClick={() => mobileMenuOpen()}>
                                            {
                                                open === "closed" ?
                                                <GiHamburgerMenu size={36}/> :
                                                    <MdClose size={36} />
                                            }
                                        </span>
                                        <ul id="mobile_menu2" className="et_mobile_menu"><li className="et_pb_menu_page_id-106 menu-item menu-item-type-post_type menu-item-object-page menu-item-111 et_first_mobile_item"><a href="https://hyperzone.omnihyper.com/research-placement/">RESEARCH &amp; PLACEMENT</a></li>
                                            <li className="et_pb_menu_page_id-261 menu-item menu-item-type-post_type menu-item-object-page menu-item-265"><a href="https://hyperzone.omnihyper.com/solutions/">SOLUTIONS</a></li>
                                            <li className="et_pb_menu_page_id-460 menu-item menu-item-type-post_type menu-item-object-page menu-item-465"><a href="https://hyperzone.omnihyper.com/pricing/">PRICING</a></li>
                                            <li className="et_pb_menu_page_id-242522 menu-item menu-item-type-post_type menu-item-object-page menu-item-242530"><a href="https://hyperzone.omnihyper.com/results/">RESULTS</a></li>
                                            <li className="et_pb_menu_page_id-652 menu-item menu-item-type-post_type menu-item-object-page menu-item-678"><a href="https://hyperzone.omnihyper.com/faq/">FAQ</a></li>
                                            <li className="et_pb_menu_page_id-243085 menu-item menu-item-type-post_type menu-item-object-page menu-item-243987"><a href="https://hyperzone.omnihyper.com/news-blog/">NEWS &amp; BLOG</a></li>
                                        </ul></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"header-column3"}>
                    <div className={"header-button-wrapper"}>
                        <a href="#" onClick={() => {
                            window.Calendly.initPopupWidget({
                                url: 'https://outlook.office365.com/book/OwnYourLocalMarketwithHyperZone@omnihyper.com/',
                                textColor: '#ffffff',
                                branding: false
                            });
                            return false;
                        }} className="btn-calendly">
                            FREE DEMO
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )

}

export default Header;