import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Header from "./components/Header";
import {LanguageSelector} from "./components/LanguageSelector";
import LinearProgressWithLabel from "./components/ui/LinearProgressWithLabel";
import Overview from "./components/Overview";
import DemandMap from "./components/DemandMap";
import Competitors from "./components/Competitors";
import CrawlerPagespeed from "./components/CrawlerPagespeed";
import SocialLinks from "./components/SocialLinks";
import AIRecommendation from "./components/AIRecommendation";
import Form from "./components/Form";
import imageExample from "./images/localis-visual-example.webp";
import Footer from "./components/Footer";
import * as XLSX from "xlsx";

function FetchData() {
    const {t} = useTranslation("common");

    const { languageCode, chosenRegion, name, country } = useParams();
    const navigate = useNavigate();
    const [businessName, setBusinessName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [results, setResults] = useState([])
    const [gmb, setGmb] = useState();
    const [backlinkOverview, setBacklinkOverview] = useState();
    const [competitorsOrganicSearch, setCompetitorsOrganicSearch] = useState([]);
    const [domainOverview, setDomainOverview] = useState();
    const [competitorsGmbInfo, setCompetitorsGmbInfo] = useState([]);
    const [competitorsBacklinksOverview, setCompetitorsBacklinksOverview] = useState([]);
    const [competitors, setCompetitors] = useState([]);
    const [competitorsDomainOverview, setCompetitorsDomainOverview] = useState([]);
    const [keywordOverviewOrganic, setKeywordOverviewOrganic] = useState([]);
    const [competitorsPagespeed, setCompetitorsPagespeed] = useState([]);
    const [competitorsStructuredData, setCompetitorsStructuredData] = useState([]);
    const [pageSpeed, setPageSpeed] = useState();
    const [mapImage, setMapImage] = useState();
    const [structuredData, setStructuredData] = useState();
    const [homepageMetaDescription, setHomepageMetaDescription] = useState();
    const [homepageTitleTag, setHomepageTitleTag] = useState();
    const [crawler, setCrawler] = useState({});
    const [pageTitleDuplicate, setPageTitleDuplicate] = useState({});
    const [missingMetaDescription, setMissingMetaDescription] = useState({});
    const [h2TitleDuplicate, setH2TitleDuplicate] = useState({});
    const [h1TitleDuplicate, setH1TitleDuplicate] = useState({});
    const [missingAltImagesDesc, setMissingAltImagesDesc] = useState({});
    const [isSeoFriendly, setIsSeoFriendly] = useState({});
    const [isRedirect, setIsRedirect] = useState({});
    const [clientErrors, setClientErrors] = useState({});
    const [socialMedia, setSocialMedia] = useState([]);
    const [blogPosts, setBlogPosts] = useState();
    const [competitorsBlogPosts, setCompetitorsBlogPosts] = useState([]);
    const [backlinks, setBacklinks] = useState([]);
    const [progress, setProgress] = React.useState(0);
    const [googleReviewsPercentage, setGoogleReviewsPercentage] = useState(0);
    const [data, setData] = useState([]);
    const [benchmarkSector, setBenchmarkSector] = useState(null);
    const [backlinkPercentage, setBacklinkPercentage] = useState(0);
    const [websitePagesPercentage, setWebsitePagesPercentage] = useState(0);
    const [technicalCompliancePercentage, setTechnicalCompliancePercentage] = useState(null);
    const [pagespeedPercentage, setPagespeedPercentage] = useState(0);
    const [socialMediaPercentage, setSocialMediaPercentage] = useState(0);
    const [localLinksPercentage, setLocalLinksPercentage] = useState(0);
    const [overallBenchmark, setOverallBenchmark] = useState(0);
    const [requestMade, setRequestMade] = useState(false);
    const [aIRecommendation, setAIRecommendation] = useState(0);
    const [quote, setQuote] = useState("");
    const [quoteRandom, setQuoteRandom] = useState(0);
    const [language, setLanguage] = useState("en-GB");
    const [competitorsDfs, setCompetitorsDfs] = useState([]);
    const [quotes, setQuotes] = useState([]);

    const W1 = 20;
    const W2 = 10;
    const W3 = 25;
    const W4 = 15;
    const W5 = 15;
    const W6 = 10;
    const W7 = 5;

    useEffect(() => {
        if (languageCode && chosenRegion && name && country) {
            handleFetchData(languageCode, chosenRegion, name, country);
        } else {
            navigate("/")
        }
    }, [languageCode, chosenRegion, name, country]);

    const handleFetchData = (languageCode, chosenRegion, name, country) => {
        // Reset state logic here
        setResults([])
        setGmb(null);
        setBacklinkOverview(null)
        setDomainOverview(null)
        setCompetitorsBacklinksOverview([])
        setPageSpeed(null)
        setCompetitorsPagespeed([])
        setMapImage(null)
        setStructuredData(null)
        setCompetitorsStructuredData([])
        setHomepageMetaDescription(null)
        setHomepageTitleTag(null)
        setCompetitors([])
        setCompetitorsDomainOverview([])
        setKeywordOverviewOrganic([])
        setCompetitorsOrganicSearch([])
        setCompetitorsGmbInfo([])
        setError('')
        setLoading(false)
        setCrawler({})
        setPageTitleDuplicate({})
        setMissingMetaDescription({})
        setH2TitleDuplicate({})
        setH1TitleDuplicate({})
        setMissingAltImagesDesc({})
        setIsSeoFriendly({})
        setClientErrors({})
        setIsRedirect({})
        setBlogPosts(null)
        setCompetitorsBlogPosts([])
        setBacklinks([])
        setSocialMedia([])
        setProgress(0)
        setGoogleReviewsPercentage(0)
        setTechnicalCompliancePercentage(null)
        setBacklinkPercentage(0)
        setPagespeedPercentage(0)
        setSocialMediaPercentage(0)
        setLocalLinksPercentage(0)
        setWebsitePagesPercentage(0)
        setBenchmarkSector(0)
        setOverallBenchmark(0)
        setRequestMade(false)
        setAIRecommendation(0)
        setCompetitorsDfs([])
        setQuote('');
        setQuoteRandom(0);
        setBusinessName("")

        setLanguage(languageCode);

        // Fetch data logic
        const incrementProgress = (target) => {
            setProgress((prevProgress) => {
                if (prevProgress < target) {
                    return prevProgress + Math.floor(Math.random() * 3);
                } else {
                    clearInterval(interval);
                    return prevProgress;
                }
            });
        };

        setBusinessName(name)

        let interval = setInterval(() => incrementProgress(37), 2200);
        let firstResponseReceived = false;

        const urls = [
            `https://localis.tech/localisbest/api/seo-audit/${name}/false/${chosenRegion}/${country}/${languageCode}`,
            `https://localis.tech/localisbest/crawl/api/seo-audit/${name}/false/${languageCode}`
        ];
        //const urlsLocalHost = [
        //    `http://localhost:8081/api/seo-audit/${name}/false/${chosenRegion}/${country}/${languageCode}`,
        //    `http://localhost:8081/crawl/api/seo-audit/${name}/false/${languageCode}`
        //];
        setLoading(true);

        const fetchPromises = urls.map((url, index) =>
            fetch(url).then(response => {
                if (response.status === 404) {
                    throw new Error(`404: The requested resource was not found.`);
                } else if (response.status === 504) {
                    throw new Error(`504: The server is currently unavailable. Please try again later.`);
                }
                return response;
            })
                .then(data => {
                    if (!firstResponseReceived) {
                        firstResponseReceived = true;
                        clearInterval(interval);
                        setProgress(40);
                        interval = setInterval(() => incrementProgress(77), 2200);
                    } else {
                        clearInterval(interval);
                        setProgress(80);
                        interval = setInterval(() => incrementProgress(97), 2200);
                    }
                    return data;
                })
        );

        Promise.all(fetchPromises)
            .then(responses => Promise.all(responses.map(response => response.json())))
            .then(data => {
                // Process the data
                if (data) {
                    setResults(data)
                    setGmb(data[0]['gmb']);
                    if (data[0]['backlinks_overview']) {
                        setBacklinkOverview(data[0]['backlinks_overview'][0])
                    }
                    setDomainOverview(data[0]['domain_overview'])
                    setCompetitorsBacklinksOverview(data[0]['competitors_backlinks_overview'])
                    setPageSpeed(data[0]['pagespeed'])
                    setCompetitorsPagespeed(data[0]["competitors_pagespeed"])
                    setMapImage(data[0]["map_image"])
                    setStructuredData(data[0]["structured_data"])
                    setCompetitorsStructuredData(data[0]["competitors_structured_data"])
                    setHomepageMetaDescription(data[0]["homepage_meta_description"])
                    setHomepageTitleTag(data[0]["homepage_title_tag"])
                    setCrawler(data[1]["website_crawler"])
                    setSocialMedia(data[0]["social_media"])
                    setBlogPosts(data[0]["blog_posts"])
                    setCompetitorsBlogPosts(data[0]["competitors_blog_posts"])
                    setBacklinks(data[0]["backlinks"])
                    if (data[0]["keyword_overview"]) {
                        setKeywordOverviewOrganic(data[0]["keyword_overview"])
                    }
                    if (data[0]['competitors']) {
                        setCompetitors(data[0]['competitors']["places"])
                        setCompetitorsDomainOverview(data[0]["competitors_domain_overview"])
                    } else if (data[0]['competitors_gmb_info']) {
                        setCompetitorsOrganicSearch(data[0]['competitors_organic_search'])
                        setCompetitorsGmbInfo(data[0]['competitors_gmb_info'])
                    } else if (data[0]['competitors_dfs']) {
                        setCompetitorsDfs(data[0]['competitors_dfs'])
                        setCompetitorsDomainOverview(data[0]["competitors_domain_overview"])
                    }
                    if (data.error) {
                        setError('Enter a valid business name. Please try again.')
                    }
                } else {
                    setError('Enter a valid business name. Please try again.')
                }
            })
            .catch(error => {
                // Handle any errors
                if (error.message.includes('404')) {
                    setError(`404: The requested resource was not found.`);
                } else if (error.message.includes('504')) {
                    setError(`504: The server is currently unavailable. Please try again later.`);
                } else if (error.message.includes('HTTP error')) {
                    setError(`We encountered a problem with the server: ${error.message}`);
                } else if (error.message.includes('Unexpected token')) {
                    setError('We received an invalid response from the server.');
                } else {
                    setError('We encountered a problem. Try again later.');
                }
                console.error(error);
                clearInterval(interval);
                setProgress(100);
                setTimeout(() => setLoading(false), 1500);
            });
    }

    let cat1 = t('app.noCat');
    let cat2 = t('app.noCat');

    if (gmb && gmb.category2) {
        cat2 = gmb.category2.charAt(0).toUpperCase() + gmb.category2.slice(1);
        cat1 = gmb.category
    } else if (gmb && gmb.category) {
        cat1 = gmb.category
    }

    const checkForDuplicates = (array) => {
        const counts = {};
        let numberOfDuplicates = 0;

        for (const item of array) {
            counts[item] = (counts[item] || 0) + 1;
        }

        for (const count in counts) {
            if (counts[count] > 1) {
                numberOfDuplicates += counts[count] - 1;
            }
        }

        if (numberOfDuplicates > 0) {
            return {status: t('app.fail'), description: numberOfDuplicates};
        } else {
            return {status: t('app.pass'), description: 0};
        }
    };

    const countFalseValues = (array) => {
        const falseCount = array.filter(item => item === false).length;

        return falseCount > 0
            ? {status: t('app.fail'), description: falseCount}
            : {status: t('app.pass'), description: 0};
    };

    const countTrueValues = (array) => {
        const trueCount = array.filter(item => item === true).length;

        return trueCount > 0
            ? {status: t('app.fail'), description: trueCount}
            : {status: t('app.pass'), description: 0};
    };

    useEffect(() => {
        if (crawler === null) {
            setTechnicalCompliancePercentage(0)
        } else if (crawler && Object.keys(crawler).length) {
            if (crawler.page_titles && crawler.page_titles.length) {
                setPageTitleDuplicate(checkForDuplicates(crawler.page_titles));
            } else if (crawler.page_titles && crawler.page_titles.length === 0) {
                setPageTitleDuplicate({status: t('app.pass'), description: 0})
            }

            if (crawler.meta_descriptions && crawler.meta_descriptions.length) {
                setMissingMetaDescription(countFalseValues(crawler.meta_descriptions));
            } else if (crawler.meta_descriptions && crawler.meta_descriptions.length === 0) {
                setMissingMetaDescription({status: t('app.pass'), description: 0})
            }

            if (crawler.h2_tags && crawler.h2_tags.length) {
                setH2TitleDuplicate(checkForDuplicates(crawler.h2_tags));
            } else if (crawler.h2_tags && crawler.h2_tags.length === 0) {
                setH2TitleDuplicate({status: t('app.pass'), description: 0})
            }

            if (crawler.h1_tags && crawler.h1_tags.length) {
                setH1TitleDuplicate(checkForDuplicates(crawler.h1_tags));
            } else if (crawler.h1_tags && crawler.h1_tags.length === 0) {
                setH1TitleDuplicate({status: t('app.pass'), description: 0})
            }

            if (crawler.no_image_alt && crawler.no_image_alt.length) {
                setMissingAltImagesDesc(countTrueValues(crawler.no_image_alt));
            } else if (crawler.no_image_alt && crawler.no_image_alt.length === 0) {
                setMissingAltImagesDesc({status: t('app.pass'), description: 0})
            }

            if (crawler.is_seo_friendly && crawler.is_seo_friendly.length) {
                setIsSeoFriendly(countFalseValues(crawler.is_seo_friendly));
            } else if (crawler.is_seo_friendly && crawler.is_seo_friendly.length === 0) {
                setIsSeoFriendly({status: t('app.pass'), description: 0})
            }

            if (crawler.is_4xx_code && crawler.is_4xx_code.length) {
                setClientErrors(countTrueValues(crawler.is_4xx_code));
            } else if (crawler.is_4xx_code && crawler.is_4xx_code.length === 0) {
                setClientErrors({status: t('app.pass'), description: 0})
            }

            if (crawler.is_redirect && crawler.is_redirect.length) {
                setIsRedirect(countTrueValues(crawler.is_redirect));
            } else if (crawler.is_redirect && crawler.is_redirect.length === 0) {
                setIsRedirect({status: t('app.pass'), description: 0})
            }

            const faults = pageTitleDuplicate.description +
                missingMetaDescription.description +
                h2TitleDuplicate.description +
                h1TitleDuplicate.description +
                missingAltImagesDesc.description +
                isSeoFriendly.description +
                clientErrors.description +
                isRedirect.description;

            let technicalCompliance = customRound((1 - (faults / crawler.number_pages_crawled)) * 100);

            if (technicalCompliance < 0) {
                setTechnicalCompliancePercentage(0)
            } else if (technicalCompliance > 100) {
                setTechnicalCompliancePercentage(100)
            } else {
                setTechnicalCompliancePercentage(technicalCompliance)
            }
        }
    }, [crawler, clientErrors.description, h1TitleDuplicate.description, h2TitleDuplicate.description,
        isRedirect.description, isSeoFriendly.description, missingAltImagesDesc.description,
        missingMetaDescription.description, pageTitleDuplicate.description]);

    useEffect(() => {
        let document = 'LocalIs_GMB_Category_Benchmark_2024(CJ).xlsx';
        if (language === "fr-FR") {
            document = 'Localis_GMB_categories_French.xlsx'
        } else if (language === "es-ES") {
            document = 'Localis_Spanish_GMB_categories.xlsx'
        }
        fetch(document)
            .then(response => response.arrayBuffer())
            .then(arrayBuffer => {

                const workbook = XLSX.read(arrayBuffer, {type: 'array'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, {header: 1});
                setData(jsonData);
            });
    }, [language]);

    useEffect(() => {
        if (competitors && gmb && gmb.rating) {
            const highestUserRatingCount = competitors.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (competitorsGmbInfo && gmb && gmb.rating) {
            const highestUserRatingCount = competitorsGmbInfo.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (competitorsDfs && gmb && gmb.rating) {
            const highestUserRatingCount = competitorsDfs.reduce((maxUserRatingCount, competitor) => {
                const userRatingCount = competitor.userRatingCount || 0;
                return userRatingCount > maxUserRatingCount ? userRatingCount : maxUserRatingCount;
            }, 0);

            let GoogleReviewsPercentage = (gmb.rating.votes_count / highestUserRatingCount) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        } else if (gmb && gmb.rating) {
            let GoogleReviewsPercentage = (gmb.rating.votes_count / 0) * 100;

            if (GoogleReviewsPercentage > 100) {
                GoogleReviewsPercentage = 100;
            }

            setGoogleReviewsPercentage(GoogleReviewsPercentage)
        }

    }, [competitors, gmb, competitorsGmbInfo, competitorsDfs]);

    useEffect(() => {
        if (data.length > 0 && gmb) {
            // Convert the first row to column headers
            const headers = data[0];
            const rows = data.slice(1);
            const categoryIndex = headers.indexOf('Primary Category');
            const benchmarkIndex = headers.indexOf('Benchmark');

            if (categoryIndex > -1 && benchmarkIndex > -1) {
                const categoryData = rows.find(row => row[categoryIndex] === gmb.category);
                if (categoryData) {
                    setBenchmarkSector(categoryData[benchmarkIndex]);
                } else {
                    setBenchmarkSector(45);
                }
            } else {
                setBenchmarkSector(45);
            }
        }
    }, [data, gmb]);

    useEffect(() => {
        if (competitorsBacklinksOverview && backlinkOverview) {
            const highestCompetitorTotal = competitorsBacklinksOverview.reduce((max, competitor) => {
                let competitorBacklinks;
                if (competitor[0]) {
                    competitorBacklinks = competitor[0].total
                } else {
                    competitorBacklinks = 0
                }
                const total = parseInt(competitorBacklinks, 10);
                return total > max ? total : max;
            }, 0);

            const myBacklinksTotal = parseInt(backlinkOverview.total, 10);
            let percentage = (myBacklinksTotal / highestCompetitorTotal) * 100;

            percentage = customRound(percentage);
            if (percentage > 100) {
                setBacklinkPercentage(100)
            } else if (percentage < 0) {
                setBacklinkPercentage(0)
            } else {
                setBacklinkPercentage(percentage);
            }
        } else {
            setBacklinkPercentage(0);
        }

        if (crawler) {
            const pagesCrawled = parseInt(crawler.total_urls, 10);
            let pagesPercentage = (pagesCrawled / 30) * 10;

            if (pagesPercentage > 100) {
                pagesPercentage = 100;
            }

            pagesPercentage = customRound(pagesPercentage);
            if (pagesPercentage > 100) {
                setWebsitePagesPercentage(100)
            } else if (pagesPercentage < 0) {
                setWebsitePagesPercentage(0)
            } else {
                setWebsitePagesPercentage(pagesPercentage);
            }
        } else {
            setWebsitePagesPercentage(0);
        }

        if (!pageSpeed) {
            setPagespeedPercentage(0)
        } else if (pageSpeed && pageSpeed.error) {
            setPagespeedPercentage(0)
        } else {
            let psPercentage = Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100)
            if (psPercentage > 100) {
                setPagespeedPercentage(100)
            } else if (psPercentage < 0) {
                setPagespeedPercentage(0)
            } else {
                setPagespeedPercentage(psPercentage)
            }
        }

        if (!socialMedia) {
            setSocialMediaPercentage(0)
        } else if (socialMedia && Array.isArray(socialMedia)) {
            const ownedAccounts = socialMedia.filter(account => account.owned_by_business);
            let totalOwned = ownedAccounts.length;
            if (totalOwned > 5) totalOwned = 5
            const percentageOwned = (totalOwned / 5) * 100;

            if (percentageOwned > 100) {
                setSocialMediaPercentage(100)
            } else if (percentageOwned < 0) {
                setSocialMediaPercentage(0)
            } else {
                setSocialMediaPercentage(customRound(percentageOwned))
            }
        }

        if (Array.isArray(backlinks)) {
            const numberSocialLinks = backlinks.length;

            if (numberSocialLinks === 10) {
                setLocalLinksPercentage(75)
            } else if (numberSocialLinks > 5 && numberSocialLinks < 10) {
                setLocalLinksPercentage(50)
            } else if (numberSocialLinks > 0 && numberSocialLinks < 6) {
                setLocalLinksPercentage(25)
            } else {
                setLocalLinksPercentage(0)
            }
        }
    }, [pageSpeed, backlinkOverview, competitorsBacklinksOverview, crawler, socialMedia, backlinks]);

    useEffect(() => {
        setOverallBenchmark(customRound(((W1 * backlinkPercentage) + (W2 * websitePagesPercentage) +
            (W3 * technicalCompliancePercentage) + (W4 * pagespeedPercentage) + (W7 * localLinksPercentage) +
            (W5 * customRound(googleReviewsPercentage)) + (W6 * socialMediaPercentage)) / 100))
    }, [backlinkPercentage, websitePagesPercentage, technicalCompliancePercentage, pagespeedPercentage,
        googleReviewsPercentage, socialMediaPercentage, localLinksPercentage]);

    const customRound = (number) => {
        const integerPart = Math.floor(number);
        const decimalPart = number - integerPart;
        if (decimalPart >= 0.5) {
            return Math.ceil(number);
        } else {
            return Math.floor(number);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (gmb && overallBenchmark !== 0 && !isNaN(overallBenchmark) && !requestMade && technicalCompliancePercentage !== null) {
                const business_name = gmb.title;
                const business_type = gmb.category;
                const location = gmb.address;
                const website = gmb.url;

                const urlAi = `https://localis.tech/localisbest/ai/api/seo-audit/ai-post/`;
                //const urlAiLocalhost = "http://localhost:8081/ai/api/seo-audit/ai-post/";
                setRequestMade(true);

                const response = await fetch(urlAi, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        business_name: business_name,
                        business_type: business_type,
                        location: location,
                        website: website,
                        overall_benchmark: overallBenchmark,
                        backlinks: backlinkPercentage,
                        website_pages: websitePagesPercentage,
                        technical_compliance: technicalCompliancePercentage,
                        pagespeed: pagespeedPercentage,
                        google_reviews: customRound(googleReviewsPercentage),
                        social_media: socialMediaPercentage,
                        local_links: localLinksPercentage,
                        language: language
                    })
                })
                const result = await response.json();
                setAIRecommendation(result.ai_recommendation)
                setProgress(100);
                setTimeout(() => setLoading(false), 1500);
            } else if (gmb && !mapImage) {
                setProgress(100);
                setTimeout(() => setLoading(false), 1500);
            } else if (gmb && isNaN(technicalCompliancePercentage) && results.length === 2) {
                if (results[1].website_crawler.total_urls === 0) {
                    setTechnicalCompliancePercentage(0)
                }
            } else if (!gmb && results.length !== 0) {
                setProgress(100);
                setTimeout(() => setLoading(false), 1500);
            }
        };
        fetchData();
    }, [
        overallBenchmark,
        backlinkPercentage,
        websitePagesPercentage,
        technicalCompliancePercentage,
        pagespeedPercentage,
        googleReviewsPercentage,
        gmb,
        requestMade,
        results,
        mapImage,
        language,
        socialMediaPercentage,
        localLinksPercentage
    ]);

    useEffect(() => {
        setQuotes([
            t('app.quote1'),
            t('app.quote2'),
            t('app.quote3'),
            t('app.quote4'),
        ]);
    }, [t]);

    useEffect(() => {
        const getRandomQuote = () => {
            const ran = Math.floor(Math.random() * quotes.length);
            return quotes[ran];
        };

        if (quotes.length > 0) {
            if (progress < 25 && progress >= 0 && quoteRandom !== 1) {
                setQuote(getRandomQuote());
                setQuoteRandom(1);
            } else if (progress < 75 && progress >= 25 && quoteRandom !== 2) {
                setQuote(getRandomQuote());
                setQuoteRandom(2);
            } else if (progress < 100 && progress >= 75 && quoteRandom !== 3) {
                setQuote(getRandomQuote());
                setQuoteRandom(3);
            }
        }
    }, [progress, quoteRandom, quotes]);

    return (
        <>
            <Header/>
            <div className="container">
                <LanguageSelector/>
                <h1 className="title">Localis</h1>
                <p className="first-subtitle">{t('app.subtitle')}</p>
                {loading && <div>
                    <LinearProgressWithLabel value={progress}/>
                    <p className={"quote"}>{quote}</p>
                </div>}
                {error && <p style={{color: 'red'}}>{error}</p>}
                {
                    results.length !== 0 && !loading ?
                        <div className={"fetch"}>
                            <Overview gmb={gmb} name={businessName} cat1={cat1} cat2={cat2} blogPosts={blogPosts}
                                      backlinkPercentage={backlinkPercentage}
                                      websitePagesPercentage={websitePagesPercentage}
                                      technicalCompliancePercentage={technicalCompliancePercentage}
                                      pagespeedPercentage={pagespeedPercentage} overallBenchmark={overallBenchmark}
                                      googleReviewsPercentage={customRound(googleReviewsPercentage)}
                                      benchmarkSector={benchmarkSector} socialMediaPercentage={socialMediaPercentage}
                                      localLinksPercentage={localLinksPercentage}/>
                            <DemandMap mapImage={mapImage} keywordOverviewOrganic={keywordOverviewOrganic}
                                       competitors={competitors} competitorsGmbInfo={competitorsGmbInfo} gmb={gmb}
                                       competitorsDfs={competitorsDfs}/>
                            <Competitors competitors={competitors} competitorsOrganicSearch={competitorsOrganicSearch}
                                         gmb={gmb} competitorsDfs={competitorsDfs}
                                         businessName={businessName} domainOverview={domainOverview}
                                         backlinkOverview={backlinkOverview} cat1={cat1} cat2={cat2}
                                         structuredData={structuredData} pageSpeed={pageSpeed} blogPosts={blogPosts}
                                         competitorsDomainOverview={competitorsDomainOverview}
                                         competitorsBacklinksOverview={competitorsBacklinksOverview}
                                         competitorsStructuredData={competitorsStructuredData}
                                         competitorsPagespeed={competitorsPagespeed}
                                         competitorsBlogPosts={competitorsBlogPosts}
                                         competitorsGmbInfo={competitorsGmbInfo}
                            />
                            <CrawlerPagespeed crawler={crawler} structuredData={structuredData} pageSpeed={pageSpeed}
                                              homepageMetaDescription={homepageMetaDescription}
                                              homepageTitleTag={homepageTitleTag} isSeoFriendly={isSeoFriendly}
                                              pageTitleDuplicate={pageTitleDuplicate}
                                              missingMetaDescription={missingMetaDescription}
                                              h1TitleDuplicate={h1TitleDuplicate} h2TitleDuplicate={h2TitleDuplicate}
                                              isRedirect={isRedirect} clientErrors={clientErrors}
                                              missingAltImagesDesc={missingAltImagesDesc}/>
                            <SocialLinks backlinks={backlinks} socialMedia={socialMedia}/>
                            <AIRecommendation aIRecommendation={aIRecommendation}/>
                            <Form/>
                        </div>

                        : results.length === 0 && !loading ?
                            <div className={"intro"}>
                                <div className={""}>
                                    <p>
                                        {t('app.intro1')}
                                        <br/><br/><br/>
                                        {t('app.intro2')}
                                        <br/><br/>
                                        {t('app.intro3')}
                                    </p>
                                </div>
                                <div className={""}>
                                    <img src={imageExample} alt={t('app.exampleImgAlt')}/>
                                </div>
                            </div> :
                            null
                }
            </div>
            <Footer/>
        </>
    );
}

export default FetchData;